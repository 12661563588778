import "../style.css";
import { BrowserView, MobileView, } from 'react-device-detect';

export default function ThreeOne() {

  return (
    <div>
    <div className="container">

   
  </div>
  <div style={{display:"flex", margin:"auto"}}>

    <BrowserView>
    <table>
      <tr>
      <td><img src={require("./Images/3-1.jpg")} style={{height:"600px"}}></img></td>
      <h1 className="headline"style={{paddingLeft:"25px", display:"flex"}}> Vermessung / Flurstückserfassung</h1>
        <td style={{paddingLeft:"25px", display:"flex"}}>
          <ul>
            <li>Digitale Flächenerfassung und Dokumentation​</li>
          </ul>
        </td>
      </tr>
    </table>

    </BrowserView>
<MobileView>
<table>
      <tr>
      <td><img src={require("./Images/3-1.jpg")} style={{height:"auto", width:"30%"}} ></img></td>
      </tr>
      <tr>
      <td style={{display:"flex", justifyContent:"left", width:"90%"}}>
      Vermessung / Flurstückserfassung      
      <br />

      </td>
      <tr>
        <td style={{display:"flex", justifyContent:"center", width:"70%"}}>
      <ul>  
      <li>Digitale Flächenerfassung und Dokumentation​</li>

      </ul>
        </td>
      </tr>      
      </tr>
      

    </table>
</MobileView>
  </div>
  </div>
  );
  }

