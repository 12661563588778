import { React } from 'react'
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { Controller, Scene } from "react-scrollmagic-r18";
import Sequence from "./Sequence";
import { gsap, ScrollTrigger } from "gsap/all";
import "./Video.css";
import Scrollmagic2 from "./Scrollmagic2"
import Scrollmagic3 from './Scrollmagic3';
import Scrollmagic4 from './Scrollmagic4';
import Scrollmagic5 from './Scrollmagic5';
import ScrollToTop from "./Router-Scripts/ScrollToTop"
import { BrowserRouter as Router, Link } from 'react-router-dom';
import LogoSlider from "./Slider"
import { BrowserView, MobileView, } from 'react-device-detect';
import MobileHome from './MobileHome';





gsap.registerPlugin(ScrollTrigger);





export default function Scrollmagic() {



const [ref] = useInView()

  useEffect(() => {
    gsap.to(".b", {

      scrollTrigger: {
        trigger: ".b",
        start: "700px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });


  useEffect(() => {
    gsap.to(".c", {

      scrollTrigger: {
        trigger: ".c",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });


  useEffect(() => {
    gsap.to(".d", {

      scrollTrigger: {
        trigger: ".d",
        start: "700px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".e", {

      scrollTrigger: {
        trigger: ".e",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });


  useEffect(() => {
    gsap.to(".f", {

      scrollTrigger: {
        trigger: ".f",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".g", {

      scrollTrigger: {
        trigger: ".g",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".h", {

      scrollTrigger: {
        trigger: ".h",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".i", {

      scrollTrigger: {
        trigger: ".i",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".j", {

      scrollTrigger: {
        trigger: ".j",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".k", {

      scrollTrigger: {
        trigger: ".k",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".iz", {

      scrollTrigger: {
        trigger: ".iz",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  
  useEffect(() => {
    gsap.to(".jz", {

      scrollTrigger: {
        trigger: ".jz",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });


  useEffect(() => {
    gsap.to(".l", {

      scrollTrigger: {
        trigger: ".l",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });
  useEffect(() => {
    gsap.to(".m", {

      scrollTrigger: {
        trigger: ".m",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".w", {

      scrollTrigger: {
        trigger: ".w",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".x", {

      scrollTrigger: {
        trigger: ".x",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });
  useEffect(() => {
    gsap.to(".y", {

      scrollTrigger: {
        trigger: ".y",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });
  useEffect(() => {
    gsap.to(".z", {

      scrollTrigger: {
        trigger: ".z",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".az", {

      scrollTrigger: {
        trigger: ".az",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });
  useEffect(() => {
    gsap.to(".bz", {

      scrollTrigger: {
        trigger: ".bz",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".cz", {

      scrollTrigger: {
        trigger: ".cz",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".dz", {

      scrollTrigger: {
        trigger: ".dz",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".ez", {

      scrollTrigger: {
        trigger: ".ez",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".fz", {

      scrollTrigger: {
        trigger: ".fz",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".gz", {

      scrollTrigger: {
        trigger: ".gz",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".hz", {

      scrollTrigger: {
        trigger: ".hz",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".lz", {

      scrollTrigger: {
        trigger: ".lz",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".lz", {

      scrollTrigger: {
        trigger: ".lz",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  useEffect(() => {
    gsap.to(".mz", {

      scrollTrigger: {
        trigger: ".mz",
        start: "900px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });

  
  return (
    <div className="wrapper">
      <MobileView>
        <MobileHome />
      </MobileView>

      <BrowserView>
      <div id="root"></div>
      <Controller>
        <Scene duration="200%" triggerHook="0" pin>
          {progress => (
            <div style={{ width: "100%", height: "100vh", position: "relative" }}>
              <div className="box b" style={{ opacity: "0" }}> WIR DIGITALISIEREN DEN WALD</div>
              <div className="box c" style={{ opacity: "0" }}> <br /><font class="secondrow">WOOD.IN.VISION ermöglicht eine dynamische Betriebssteuerung <br></br>für die nächste Generation Forstwirtschaft.  <br></br>Wir denken in digital gestützten Prozessen, entwickeln <br></br>und optimieren umfassende Anwendungen mit und für unsere Kunden. <br /><br></br> WOOD.IN.VISION – Soft- und Hardware entwickelt von und für Forstprofis. <br /> Aus Erfahrung nachhaltig und effizient.
              <br></br>
              <br></br>
             <Link to='/kontakt' class="Contact">Jetzt Anfragen</Link>
 
 </font></div>
              <Sequence ref={ref} progress={progress} />
            </div>
          )}

        </Scene>
      </Controller>

      <ScrollToTop />

      <Scrollmagic2><ScrollToTop /></Scrollmagic2>
      <ScrollToTop />

      <Scrollmagic3><ScrollToTop /></Scrollmagic3>
      <ScrollToTop />

      <Scrollmagic4><ScrollToTop /></Scrollmagic4>
      <ScrollToTop />

      <Scrollmagic5><ScrollToTop /></Scrollmagic5>
      <ScrollToTop />
{/*
      <div className="wrapper3">
        <Controller>
          <ScrollToTop />
          <Scene duration="200%" triggerHook="0" pin>
            {progress => (
              <div style={{ width: "100%", height: "100vh", position: "relative" }}>
                <ScrollToTop />
                <div className="box d" style={{ opacity: "0" }}> ABGESTIMMT AUF HEUTIGE UND ZUK&Uuml;NFTIGE BED&Uuml;RFNISSE</div>
                <div className="box e" style={{ opacity: "0" }}><font class="secondrow"><font color="green"><br />✓</font> Abbildung der kompletten Forst-Holzkette</font></div>
                <div className="box f" style={{ opacity: "0" }}><font class="secondrow"><font color="green"><br />✓</font> Echtzeitmonitoring</font></div>
                <div className="box g" style={{ opacity: "0" }}><font class="secondrow"><font color="green"><br />✓</font> Maschinenunabh&auml;ngig</font></div>
                <div className="box h" style={{ opacity: "0" }}><font class="secondrow"><font color="green"><br />✓</font> Schnittstellenübergreifend</font></div>
                <div className="box i" style={{ opacity: "0" }}><font class="secondrow"><font color="green"><br />✓</font> Grenzfeststellung auf wenige Zentimeter genau</font></div>
                <div className="box j" style={{ opacity: "0" }}><font class="secondrow"><font color="green"><br />✓</font> Optische Schadholzfr&uuml;herkennung mit hochaufl&ouml;senden Luftbildern (GeoTIFF)</font></div>
                <div className="box k" style={{ opacity: "0" }}><font class="secondrow"><font color="green"><br />✓</font> R&uuml;ckegassenplanung unter 10min</font></div>
                <div className="box iz" style={{ opacity: "0" }}><font class="secondrow"><font color="green"><br />✓</font> Routenplanung und Optimierung f&uuml;r Mulcherfl&auml;chen</font></div>
                <div className="box jz" style={{ opacity: "0" }}><font class="secondrow"><font color="green"><br />✓</font> Pflanzfl&auml;chenplaung mit Volumen und Verteilung</font></div>
                <div className="box l" style={{ opacity: "0" }}><font class="secondrow"><font color="green"><br />✓</font> Mengensteuerung</font></div>
                <div className="box m" style={{ opacity: "0" }}><font class="secondrow"><font color="green"><br />✓</font> Dauerhafte digitale Dokumentation</font></div>
                <div className="box lz" style={{ opacity: "0" }}><font class="secondrow"><font color="green"><br /></font>  &nbsp;&nbsp;&nbsp;&nbsp;uvm.</font></div>
           

              </div>
            )}

          </Scene>
        </Controller>
        <ScrollToTop />
      </div>
*/}
      <div className="wrapper4">
        <Controller>
          <ScrollToTop />
          <Scene duration="200%" triggerHook="0" pin>
            {progress => (
              <div style={{ width: "100%", height: "100vh", position: "relative" }}>
                <ScrollToTop />
                <div className="box mz" style={{ opacity: "0" }}> WIR DENKEN LANGFRISTIG UND KUNDENNAH</div>
                <div className="box dz" style={{ opacity: "0" }}><font class="secondrow"> Unser Antrieb ist es mit WOOD.IN.VISION eine zukunftsfähige Plattform mit </font></div>
                <div className="box ez" style={{ opacity: "0" }}><font class="secondrow"> digitalen Anwendungen bereit zu stellen, die eine ressourcenschonende, </font></div>
                <div className="box fz" style={{ opacity: "0" }}><font class="secondrow"> prozessorientierte, langfristig dokumentierbare und auf den Kreislaufgedanken </font></div>
                <div className="box gz" style={{ opacity: "0" }}><font class="secondrow"> basierte Waldbewirtschaftung ermöglichen. Wir handeln aus Überzeugung im </font></div>
            <div className="box hz" style={{ opacity: "0" }}><font class="secondrow"> Sinne einer ökologisch und ökonomisch nachhaltigen Forstwirtschaft. </font></div>
               
            <div style={{ width: "100%", height: "100vh", position: "relative"}}>
                <ScrollToTop />
            <div className="box w" style={{ opacity: "0" }}> FORSTWIRTSCHAFT DER N&Auml;CHSTEN GENERATION</div>
                <div className="box x" style={{ opacity: "0" }}><font class="secondrow"> WOOD.IN.VISION ist mobil on- und offline nutzbar. Alle Daten sind auch unter </font></div>
                <div className="box y" style={{ opacity: "0" }}><font class="secondrow"> schwierigen Bedingungen georeferenziert, dank unserer Royal Fix Hardware.</font></div>
                <div className="box z" style={{ opacity: "0" }}><font class="secondrow"> Die Cloud-Technologie ermöglicht es uns, alle Akteure in der Forstwirtschaft </font></div>
                <div className="box az" style={{ opacity: "0" }}><font class="secondrow"> digital und sicher miteinander zu vernetzen. Wir schaffen damit eine auf </font></div>
                <div className="box bz" style={{ opacity: "0" }}><font class="secondrow"> Transparenz und nahezu Echtzeit basierende Zusammenarbeit. Jeder Akteur </font></div>
                <div className="box cz" style={{ opacity: "0" }}><font class="secondrow"> entscheidet dabei selbst, welche Daten zur Verfügung gestellt werden.</font></div>
                
              </div>


            </div>
            )}

          </Scene>
        </Controller>
        <ScrollToTop />
      </div>


      <h1 className="headline"><center>UNSERE KUNDEN</center></h1>
              
              <LogoSlider />

   {/*   <Carousel show={4.5} slide={1} swiping={true} leftArrow={<img src={require("./Icons/Arrow_left.png")} style={{ width: "70px" }} />} rightArrow={<img src={require("./Icons/Arrow_right.png")} style={{ width: "70px" }} />}>
        <a href="https://forst.hohenzollern.com/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo2.png")} width="450" height="70" /></a>
        <a href="https://www.bundesforste.at/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo1.png")} width="450" height="70" /></a>
        <a href="https://www.hessen-forst.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo3.png")} width="450" height="70" /></a>
        <a href="https://www.stiftung-liebenau.de/service-und-produkte/dienstleistungen/forstwirtschaft/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo4.png")} width="450" height="70" /></a>
        <a href="https://www.rohde-landschaftspflege.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo5.png")} width="450" height="70" /></a>
        <a href="https://holz-bross.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo6.png")} width="450" height="70" /></a>
        <a href="https://holzhandel-fink.de/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo7.png")} width="450" height="70" /></a>
        <a href="https://forstbetrieb-schaetzle.de/startseite.html" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo9.png")} width="450" height="70" /></a>
        <a href="https://www.vieghofer.com/one-layout/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo10.png")} width="450" height="70" /></a>
        <a href="https://branchenbuch.meinestadt.de/trossingen/company/979019" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo11.png")} width="450" height="70" /></a>
            </Carousel>*/}



      <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
      <h1 className="headline"><center>UNSERE PARTNER</center></h1>
        <center>
          <table>
            <tr>
              <td>
                <a href="https://www.netwakevision.com/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo12.png")} width="450" height="70" /></a>
                <a href="https://www.neuson-forest.com/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo13.png")} width="450" height="70" /></a>
                <a href="https://www.hs-rottenburg.net/" target="_blank" rel="noopener noreferrer"><img src={require("./Logos/Logo8.png")} width="450" height="70" /></a>
              </td>

        </tr>
      </table>

      </center>


<br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
<br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>

      {/*<center>
              <table style={{ borderSpacing: "30px", width: "100%" }}>
                <tr>
                  <td style={{ width: "calc(100%/5)", marginLeft: "20%"  }}><img class="" src={require("./Logos/Logo1.png")} alt="" width="450" height="70" /></td>
                  <td style={{ width: "calc(100%/5)", marginLeft: "20%"  }}><img class="" src={require("./Logos/Logo2.png")} alt="" width="450" height="70" /></td>
                  <td style={{ width: "calc(100%/5)", marginLeft: "20%"  }}><img class="" src={require("./Logos/Logo3.png")} alt="" width="450" height="70" /></td>
                  <td style={{ width: "calc(100%/5)", marginLeft: "20%"  }}><img class="" src={require("./Logos/Logo1.png")} alt="" width="450" height="70" /></td>
                  
                </tr>
              </table>
            </center>*/}
      <h1 className="headline"><center>UNSERE PAKETE</center></h1>


      <div className='Pakete'>
        <ScrollToTop />
        <table style={{ borderSpacing: "30px", width: "100%" }}>
          <tr>
            <td style={{ width: "calc(100%/3)", marginLeft: "30%", backgroundColor: "" }}>
              <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>
                <h1 style={{ fontSize: "40px", textAlign: "center" }}>Cloud Basic</h1>
                <h2 style={{ fontSize: "20px", fontWeight: "300", textAlign: "center", marginTop: "-7%" }}>für Unternehmer</h2>

                <img class="" src={require("./images/Angebot.png")} alt="" width="300" height="278" />
                <center><Link to='/kontakt' class="Contact">Jetzt Anfragen</Link></center>
                <ul style={{ textAlign: "left" }}>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li style={{ listStyleType: "none" }}>
                        {/*<ul>
                          <li>Auftragsanlage über Karte</li>
                          <li>BI-Tool/Dashboard</li>
                          <li>Holzernte mechanisch/motormanuell</li>
                          <li>Polteraufnahme/Gassenaufzeichnung*</li>
                          <li>Stammdaten</li>
            </ul>*/}
                      </li>
                    </ul>
                    <br></br>
                    {/*} <br></br>
                    <br></br>
                    <br></br>
          <br></br>*/}
                    {/*<center>*Schnittstelle zu GIS-Software
                      und SAP möglich<br></br>
                      **Schnittstelle zu Holzvermarktungstool</center></li>
          <br></br>*/}
                  </li>
                  <ScrollToTop />

                </ul>
              </div>
            </td>

            <td style={{ width: "calc(100%/3)", marginRight: "30%", backgroundColor: "" }}>

              <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>
                <h1 style={{ fontSize: "40px", textAlign: "center" }}>Cloud Advanced</h1>
                <h2 style={{ fontSize: "20px", fontWeight: "300", textAlign: "center", marginTop: "-7%" }}>für Unternehmer und Forstservices</h2>


                <img class="" src={require("./images/Angebot.png")} alt="" width="300" height="278" />
                <center><Link to='/kontakt' class="Contact">Jetzt Anfragen</Link></center>
                <ul style={{ textAlign: "left" }}>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li style={{ listStyleType: "none" }}>
                        {/*<ul>
                          <li>Auftragsanlage über Karte</li>
                          <li>BI-Tool/Dashboard</li>
                          <li>Holzernte mechanisch/motormanuell</li>
                          <li>Stammdaten</li>
                          <li>Drohnenmonitoring</li>
                          <li>Polteraufnahme/Gassenaufzeichnung*</li>
            </ul>*/}
                      </li>
                    </ul>
                    <br></br>
                    {/*} <br></br>
                    <br></br>
          <br></br>*/}

                    {/*<center>*Schnittstelle zu GIS-Software
                      und SAP möglich<br></br>
                      **Schnittstelle zu Holzvermarktungstool</center></li>
                  <br></br>
          <br></br>*/}
                  </li>
                </ul>

              </div>

            </td>
            <td style={{ width: "calc(100%/3)", backgroundColor: "" }}>
              <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>
                <h1 style={{ fontSize: "40px", textAlign: "center" }}>Cloud Professional</h1>
                <h2 style={{ fontSize: "20px", fontWeight: "300", textAlign: "center", marginTop: "-7%" }}>für Forstservices und Waldbesitzer</h2>
                <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>
                


                  <img class="" src={require("./images/Angebot.png")} alt="" width="300" height="278" />
                  <center><Link to='/kontakt' class="Contact">Jetzt Anfragen</Link></center>
                  <ul style={{ textAlign: "left" }}>
                    <li style={{ listStyleType: "none" }}>
                      <ul>
                        <li style={{ listStyleType: "none" }}>
                          {/*} <ul>
                            <li>Auftragsanlage über Karte und Konfigurator</li>
                            <li>BI-Tool/Dashboard</li>
                            <li>Holzernte mechanisch/motormanuell</li>
                            <li>Auftragskonfigurator Unternehmerstuerung</li>
                            <li>Vollautomatisches Rückegassenschneiden</li>
                            <li>Stammdaten</li>
                            <li>Drohnenmonitoring</li>
                            <li>Forsteinrichtungsmodule</li>
                            <li>Holzlogistikschnittstelle (ELDAT)**</li>
            </ul>*/}
                        </li>
                      </ul>
                      <br></br>

                      {/*<center>*Schnittstelle zu GIS-Software
                        und SAP möglich<br></br>
                        **Schnittstelle zu Holzvermarktungstool</center>
                    <br></br>
                    <br></br>*/}

                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>

        </table>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <h1 className="headline"><center>MEHR ERFAHREN </center></h1>

      <link
        rel="stylesheet"
        href=""
      />

      {/*<center> <Player className="VideoPlayer" playsInline poster={require("./images/Thumbnail.jpg")} src="https://drive.google.com/uc?export=download&id=1ee8ryPElwsNobs9Hb02fHn1k6m61adRx">
        <BigPlayButton position="center" />
                  </Player></center>*/}

      <div >
        <BrowserView>
          <center> <iframe width="1920" height="1080" src="https://www.youtube.com/embed/RRV6iKB8W7s" title="Das ist Wood.in.Vision | Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></center>
        </BrowserView>
        <MobileView>
          <center> <iframe style={{width:"95%", height:"25vh"}} width="1920" height="1080" src="https://www.youtube.com/embed/RRV6iKB8W7s" title="Das ist Wood.in.Vision | Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></center>
        </MobileView>
      </div>


      <br></br>
      {/*} <h2 className='Blocktext'><center>W.I.V ist eine webbasierte Unternehmenslösung für Forstunternehmen,
        die Cloud-basiert betrieben wird.<br></br>
        Der Zugriff erfolgt lokal über Ihren Web-Browser und mobil
            mit unserer W.I.V App über Smartphone oder Tablet.</center></h2>*/}
      <center><Link to='https://drive.google.com/uc?export=download&id=1WIkwMxzKIbvqxmZivlVSaoBZuxe3C2C1' class="Contact">W.I.V. Broschüre Herunterladen</Link></center>
      <br></br>
      <center><Link to='https://drive.google.com/uc?export=download&id=1OuV9BLq_lVnLnlwA0i0F3A6fRi4HID12' class="Contact">W.I.V. Starter Herunterladen</Link></center>
      <br></br>



    </BrowserView>



    </div>
  )

}
