import "../style.css";
import { BrowserView, MobileView, } from 'react-device-detect';

export default function OneFive() {

  return (
    <div>
    <div className="container">

   
  </div>
  <div style={{display:"flex", margin:"auto"}}>
    <table>
      <tr>
      <BrowserView>
      <td><img src={require("./Images/1-5.jpg")} style={{height:"600px", opacity:"0"}}></img></td>
      </BrowserView>
      <h1 className="headline"style={{paddingLeft:"25px", display:"flex"}}> Auftrag Forst universal​</h1>
      <td style={{paddingLeft:"25px", display:"flex"}}>
       
         In Entwicklung. Q2/24 verfügbar.

       </td>
      </tr>
    </table>
  </div>
  </div>
  );
  }

