import React from "react";
import { BrowserRouter as Router, Routes, Switch, Route, Link } from 'react-router-dom';
import { BrowserView, MobileView, } from 'react-device-detect';


export default function Footer1() {
  return (

    <div>

<BrowserView> 

      <section className="Footer-head">
        <br></br>
      </section>
      <section className="Footer">
        <table>
          <tr>
            <td style={{ width: "calc(100%/3)" }}>
              <a href="./" ><img src={require("./images/Logo.png")} className="foot-logo"></img></a>
            </td>

            <td style={{ width: "calc(100%/3)" }}>

              <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>
                <a style={{ marginRight: "10px", fontSize: "20px" }}><Link to='/impressum' >Impressum</Link></a>
                <a style={{ marginRight: "10px", fontSize: "20px" }}><Link to='/datenschutz' >Datenschutz</Link></a>
                <a style={{ marginRight: "10px", fontSize: "20px" }}><Link to='/agb' >AGB</Link></a>
                <a style={{ marginRight: "10px", fontSize: "20px" }}><Link to='/kontakt' >Kontakt</Link></a>
                
              </div>

            </td>
            <td style={{ width: "calc(100%/3)" }}>
              <a href="https://www.facebook.com/profile.php?id=100082970450717" target="_blank"><img src={require("./images/Social/Facebook.png")} className="Social"></img></a>
              <a href="https://www.instagram.com/wood.in.vision/" target="_blank"><img src={require("./images/Social/Instagram.png")} className="Social"></img></a>
              <a href="https://www.linkedin.com/company/wood-in-vision/" target="_blank"><img src={require("./images/Social/LinkedIN.png")} className="Social"></img></a>
              <a href="https://www.youtube.com/channel/UCMCS6THRdYim-biFOeIUOAQ" target="_blank"><img src={require("./images/Social/YouTube.png")} className="Social"></img></a>
            </td>
          </tr>

        </table>
      </section>

</BrowserView>

<MobileView>

<section className="Footer-head">
        <br></br>
      </section>
      <section className="Footer">
      <center>
        <a href="./" ><img src={require("./images/Logo.png")} className="foot-logo"></img></a>
        <table>
          <tr>
            <td style={{ width: "100%" }}>

              <div style={{ textAlign: "center", alignItems: "center", alignContent: "center" }}>
                <a style={{ marginRight: "10px", fontSize: "20px" }}><Link to='/impressum' >Impressum</Link></a>
                <a style={{ marginRight: "10px", fontSize: "20px" }}><Link to='/datenschutz' >Datenschutz</Link></a>
                <br></br>
                <a style={{ marginRight: "10px", fontSize: "20px" }}><Link to='/agb' >AGB</Link></a>
                <a style={{ marginRight: "10px", fontSize: "20px" }}><Link to='/kontakt' >Kontakt</Link></a>
                
              </div>
            </td>
          </tr>
        </table>
       </center>
       <br></br>

      </section>



</MobileView>

    </div>
  )
}