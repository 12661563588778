import { React } from 'react'
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { Controller, Scene } from "react-scrollmagic-r18";
import Sequence from "./Sequence2/index2";
import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

export default function Scrollmagic() {
  const [ref] = useInView()

  useEffect(() => {
    gsap.to(".n", {

      scrollTrigger: {
        trigger: ".n",
        start: "200px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });


  useEffect(() => {
    gsap.to(".o", {

      scrollTrigger: {
        trigger: ".o",
        start: "400px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -150,
      opacity: 1,
      duration: 3,

    })
  });


  return (
    <div className="wrapper2">

      <div id="root"></div>


      <Controller>
        <Scene duration="200%" triggerHook="0" pin>
          {progress => (
            <div style={{ width: "100%", height: "100vh", position: "relative", }}>
              <div className="box n" style={{ opacity: "0" }}> WIR SIND EINFACH VIELSEITIG</div>
              <div className="box o" style={{ opacity: "0" }}> <br /><font class="secondrow"> WOOD.IN.VISION ist eine auf<br></br> Cloud-Technologie basierende, digitale <br /> Plattform. Der Zugriff erfolgt lokal  <br />über den Web-Browser und mobil mit <br />  der W.I.V App über Smartphone oder Tablet. </font></div>
              <Sequence ref={ref} progress={progress} />
            </div>
          )}

        </Scene>
      </Controller>


    </div>
  )

}