import "../style.css";
import { BrowserView, MobileView, } from 'react-device-detect';

export default function FourZero() {

  return (
    <div>
    <div className="container">

   
  </div>
  <div style={{display:"flex", margin:"auto"}}>
<BrowserView>
    <table>
      <tr>
      <td><img src={require("./Images/4-0.jpg")} style={{height:"600px"}}></img></td>
      <h1 className="headline"style={{paddingLeft:"25px", display:"flex"}}>  Jagdmanagement</h1>
      <td style={{paddingLeft:"25px", display:"flex"}}>
      Das Jagdmanagement dient der Verortung, Beschreibung und Verwaltung Ihrer Jagdbezirke, deren Grenzen, Ansitz- und Reviereinrichtungen. 
      
      <br />

      </td>
      <tr>
        <td>
      <ul style={{float:"left"}}>  
        <li>
        Erfassung von Jagd,- und Pirschbezirken    
        </li>
        <li>
        Pflege von Revier,- und Ansitzeinrichtungen mit Hochsitzkontrolle
        </li>
        <li>
        Abschussmeldungen
        </li>
        <li>
        Schweisfährte
        </li>
        <li>
        Waffenausleihe
        </li>
      </ul>
        </td>
      </tr>      
      </tr>
      

    </table>

    </BrowserView>
<MobileView>
<table>
      <tr>
      <td><img src={require("./Images/4-0.jpg")} style={{height:"auto", width:"30%"}} ></img></td>
      </tr>
      <tr>
      <td style={{display:"flex", justifyContent:"left", width:"90%"}}>
      Das Jagdmanagement dient der Verortung, Beschreibung und Verwaltung Ihrer Jagdbezirke, deren Grenzen, Ansitz- und Reviereinrichtungen. 
      
      <br />

      </td>
      <tr>
        <td style={{display:"flex", justifyContent:"center", width:"70%"}}>
      <ul>  
      <li>
        Erfassung von Jagd,- und Pirschbezirken    
        </li>
        <li>
        Pflege von Revier,- und Ansitzeinrichtungen mit Hochsitzkontrolle
        </li>
        <li>
        Abschussmeldungen
        </li>
        <li>
        Schweisfährte
        </li>
        <li>
        Waffenausleihe
        </li>
      </ul>
        </td>
      </tr>      
      </tr>
      

    </table>
</MobileView>
  </div>
  </div>
  );
  }

