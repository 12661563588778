import { React } from 'react'
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { Controller, Scene } from "react-scrollmagic-r18";
import Sequence from "./Sequence4/index4";
import { gsap, ScrollTrigger } from "gsap/all";
import { BrowserRouter as Router, Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

export default function Scrollmagic4() {
  const [ref] = useInView()

  useEffect(() => {
    gsap.to(".r", {

      scrollTrigger: {
        trigger: ".r",
        start: "200px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -50,
      opacity: 1,
      duration: 3,

    })
  });


  useEffect(() => {
    gsap.to(".s", {

      scrollTrigger: {
        trigger: ".s",
        start: "400px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -50,
      opacity: 1,
      duration: 3,

    })
  });


  return (
    <div className="wrapper2">

      <div id="root"></div>


      <Controller>
        <Scene duration="200%" triggerHook="0" pin>
          {progress => (
            <div style={{ width: "100%", height: "100vh", position: "relative", }}>
              <div className="box r" style={{ opacity: "0"}}> WIR KÖNNEN GASSEN DIGITAL </div>
              <div className="box s" style={{ opacity: "0" }}> <br /><font class="secondrow"> WOOD.IN.VISION ist die Lösung für<br></br>Die Digitale Gassenanlage auf Harvestern. Über die  <br></br>Einfache Planung am Mobilgerät bis in den Harvester <br></br>Gassenauszeichen? Mit WOOD.IN.VISION ist das  <br></br>Vergangenheit. <br></br><br></br> <Link to='https://www.lrakn.de/service-und-verwaltung/aemter/kreisforstamt/digitale+fortschritte+bei+der+holzernte+-+wood+in+vision'target="_blank" class="Contact">Artikel</Link> </font></div>
             
             
              <Sequence ref={ref} progress={progress} />
            </div>
          )}

        </Scene>
      </Controller>


    </div>
  )

}