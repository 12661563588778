import "../style.css";
import { BrowserView, MobileView, } from 'react-device-detect';

export default function TwoOne() {

  return (
    <div>
    <div className="container">

   
  </div>
  <div style={{display:"flex", margin:"auto"}}>

  <BrowserView>
    <table>
      <tr>
      <td><img src={require("./Images/2-1.jpg" )} style={{height:"600px"}}></img></td>
      <h1 className="headline"style={{paddingLeft:"25px", display:"flex"}}>  Rückegassenplanung </h1>
      <td style={{paddingLeft:"25px", display:"flex"}}>
      Digitale Rückegassenplanung mit WIV​
      
      <br />

      </td>
      <tr>
        <td>
      <ul style={{float:"left"}}>  
        <li>
          Planung von bestehenden Gassen und Zwangspunkte mittels hochgenauem GPS 
        </li>
        <li>
          Neue Gassen automatisch generieren oder manuell planen​
        </li>
        <li>
          Planungen in anderen Auftragsmodulen nutzbar​
        </li>
      </ul>
        </td>
      </tr>      
      </tr>
      

    </table>


    </BrowserView>
<MobileView>
<table>
      <tr>
      <td><img src={require("./Images/2-1.jpg")} style={{height:"auto", width:"30%"}} ></img></td>
      </tr>
      <tr>
      <td style={{display:"flex", justifyContent:"left", width:"90%"}}>
      Digitale Rückegassenplanung mit WIV​
      
      <br />

      </td>
      <tr>
        <td style={{display:"flex", justifyContent:"center", width:"70%"}}>
      <ul>  
      <li>
          Planung von bestehenden Gassen und Zwangspunkte mittels hochgenauem GPS 
        </li>
        <li>
          Neue Gassen automatisch generieren oder manuell planen​
        </li>
        <li>
          Planungen in anderen Auftragsmodulen nutzbar​
        </li>
      </ul>
        </td>
      </tr>      
      </tr>
      

    </table>
</MobileView>


  </div>
  </div>
  );
  }

