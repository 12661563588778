import { React } from 'react'
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { Controller, Scene } from "react-scrollmagic-r18";
import Sequence from "./Sequence5/index5";
import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

export default function Scrollmagic4() {
  const [ref] = useInView()

  useEffect(() => {
    gsap.to(".t", {

      scrollTrigger: {
        trigger: ".t",
        start: "200px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -50,
      opacity: 1,
      duration: 3,

    })
  });


  useEffect(() => {
    gsap.to(".u", {

      scrollTrigger: {
        trigger: ".u",
        start: "400px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -50,
      opacity: 1,
      duration: 3,

    })
  });


  return (
    <div className="wrapper3">

      <div id="root"></div>


      <Controller>
        <Scene duration="200%" triggerHook="0" pin>
          {progress => (
            <div style={{ width: "100%", height: "100vh", position: "relative", }}>
              <div className="box t" style={{ opacity: "0"}}> WIR VERSCHAFFEN ÜBERBLICK</div>
              <div className="box u" style={{ opacity: "0" }}> <br /><font class="secondrow"> WOOD.IN.VISION ist eine Plattform für alle<br></br> die unterschiedliche Tätigkeiten im Forst<br /> planen, umsetzen, dokumentieren und abrechnen <br />wollen.</font></div>
              <Sequence ref={ref} progress={progress} />
            </div>
          )}

        </Scene>
      </Controller>


    </div>
  )

}