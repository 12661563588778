import React from 'react'
import { useState } from 'react';
import Background from './Videos/Background.mp4'
import Scrollmagic from './Scrollmagic'
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { BrowserView, MobileView, } from 'react-device-detect';

export default function Page() {
  const [toggle] = useState(false);


  window.addEventListener("scroll", function () {
  })



  return (
    <div>
      <section className="banner1">

        <div className="videooverlay"><img src={require('./images/Logo.png')} className="LogoOverlay"></img> <center></center>  </div>
        <div className="container">
          <video className='videoTag' autoPlay loop muted width={"100%"}>
            <source src={Background} type='video/mp4' />
          </video>
        </div>
      </section>
      <section className="banner">
        <div className="container">
          <br></br>
          <BrowserView>
            <h1 className="headline"><center>DAS IST WOOD.IN.VISION</center></h1>
          </BrowserView>
        </div>
        <Scrollmagic />
        <center><Link to='/kontakt' class="Contact">Jetzt Anfragen</Link></center>
        <br></br>
        <br></br>
      </section>

    </div>


  )
}


