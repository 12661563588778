import A0 from "./0000.png";
import A1 from "./0001.png";
import A2 from "./0002.png";
import A3 from "./0003.png";
import A4 from "./0004.png";
import A5 from "./0005.png";
import A6 from "./0006.png";
import A7 from "./0007.png";
import A8 from "./0008.png";
import A9 from "./0009.png";

import A10 from "./0010.png";
import A11 from "./0011.png";
import A12 from "./0012.png";
import A13 from "./0013.png";
import A14 from "./0014.png";
import A15 from "./0015.png";
import A16 from "./0016.png";
import A17 from "./0017.png";
import A18 from "./0018.png";
import A19 from "./0019.png";

import A20 from "./0020.png";
import A21 from "./0021.png";
import A22 from "./0022.png";
import A23 from "./0023.png";
import A24 from "./0024.png";
import A25 from "./0025.png";
import A26 from "./0026.png";
import A27 from "./0027.png";
import A28 from "./0028.png";
import A29 from "./0029.png";

import A30 from "./0030.png";
import A31 from "./0031.png";
import A32 from "./0032.png";
import A33 from "./0033.png";
import A34 from "./0034.png";
import A35 from "./0035.png";
import A36 from "./0036.png";
import A37 from "./0037.png";
import A38 from "./0038.png";
import A39 from "./0039.png";

import A40 from "./0040.png";
import A41 from "./0041.png";
import A42 from "./0042.png";
import A43 from "./0043.png";
import A44 from "./0044.png";
import A45 from "./0045.png";
import A46 from "./0046.png";
import A47 from "./0047.png";
import A48 from "./0048.png";
import A49 from "./0049.png";

import A50 from "./0050.png";


export const Scene1 = Array.of(
  A0,
  A1,
  A2,
  A3,
  A4,
  A5,
  A6,
  A7,
  A8,
  A9,

  A10,
  A11,
  A12,
  A13,
  A14,
  A15,
  A16,
  A17,
  A18,
  A19,

  A20,
  A21,
  A22,
  A23,
  A24,
  A25,
  A26,
  A27,
  A28,
  A29,

  A30,
  A31,
  A32,
  A33,
  A34,
  A35,
  A36,
  A37,
  A38,
  A39,

  A40,
  A41,
  A42,
  A43,
  A44,
  A45,
  A46,
  A47,
  A48,
  A49,

  A50,

);
