import "../style.css";
import { BrowserView, MobileView, } from 'react-device-detect';

export default function TwoThree() {

  return (
    <div>
    <div className="container">

   
  </div>
  <div style={{display:"flex", margin:"auto"}}>

<BrowserView>
    <table>
      <tr>
      <td><img src={require("./Images/2-3.jpg")} style={{height:"600px"}}></img></td>
      <h1 className="headline"style={{paddingLeft:"25px", display:"flex"}}>Seiltrassenplanung</h1>
      <td style={{paddingLeft:"25px", display:"flex"}}>
      Flexible, anpassbare Seiltrassenplanung​
      
      <br />

      </td>
      <tr>
        <td>
      <ul style={{float:"left"}}>  
        <li>
          Zwangspunkte mittels hochgenauem GPS aufnehmen​        
        </li>
        <li>
          Trassen digital planen
        </li>
        <li>
          Trassen mithilfe des hochgenaue GPS im Bestand abstecken​
        </li>
        <li>
          Einbindung von Drohnendaten und DGM-Karten möglich​
        </li>
      </ul>
        </td>
      </tr>      
      </tr>
      

    </table>


    </BrowserView>
<MobileView>
<table>
      <tr>
      <td><img src={require("./Images/2-3.jpg")} style={{height:"auto", width:"30%"}} ></img></td>
      </tr>
      <tr>
      <td style={{display:"flex", justifyContent:"left", width:"90%"}}>
      Flexible, anpassbare Seiltrassenplanung​
      
      <br />

      </td>
      <tr>
        <td style={{display:"flex", justifyContent:"center", width:"70%"}}>
      <ul>  
      <li>
          Zwangspunkte mittels hochgenauem GPS aufnehmen​        
        </li>
        <li>
          Trassen digital planen
        </li>
        <li>
          Trassen mithilfe des hochgenaue GPS im Bestand abstecken​
        </li>
        <li>
          Einbindung von Drohnendaten und DGM-Karten möglich​
        </li>
      </ul>
        </td>
      </tr>      
      </tr>
      

    </table>
</MobileView>
  </div>
  </div>
  );
  }

