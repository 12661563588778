import { React } from 'react'
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { Controller, Scene } from "react-scrollmagic-r18";
import Sequence from "./Sequence3/index3";
import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

export default function Scrollmagic3() {
  const [ref] = useInView()

  useEffect(() => {
    gsap.to(".p", {

      scrollTrigger: {
        trigger: ".p",
        start: "200px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -50,
      opacity: 1,
      duration: 3,

    })
  });


  useEffect(() => {
    gsap.to(".q", {

      scrollTrigger: {
        trigger: ".q",
        start: "400px 5%",
        end: "1200px 5%",
        scrub: true,
        markers: false,
        toggleActions: "restart pause reverse pause"
      },
      y: -50,
      opacity: 1,
      duration: 3,

    })
  });


  return (
    <div className="wrapper3">

      <div id="root"></div>


      <Controller>
        <Scene duration="200%" triggerHook="0" pin>
          {progress => (
            <div style={{ width: "100%", height: "100vh", position: "relative", }}>
              <div className="box p" style={{ opacity: "0"}}> WIR VERBINDEN MASCHINEN</div>
              <div className="box q" style={{ opacity: "0" }}> <br /><font class="secondrow"> WOOD.IN.VISION ist die Lösung für<br></br> Unterschiedliche Maschinenflotten. Wir sind<br /> Maschinen und Hersteller unabhängig.<br />Ob auf dem Maschinen PC oder mobil auf Handy<br /> oder Tablet</font></div>
              <Sequence ref={ref} progress={progress} />
            </div>
          )}

        </Scene>
      </Controller>


    </div>
  )

}