import "../style.css";
import { BrowserView, MobileView, } from 'react-device-detect';

export default function ThreeFive() {

  return (
    <div>
    <div className="container">

   
  </div>
  <div style={{display:"flex", margin:"auto"}}>
  <BrowserView>
    <table>
      <tr>
      <td><img src={require("./Images/3-5.jpg")}style={{height:"600px"}} ></img></td>
      <h1 className="headline"style={{paddingLeft:"25px", display:"flex"}}>  Verkehrssicherheit</h1>
      <td style={{paddingLeft:"25px", display:"flex"}}>
      Mit der Verkehrssicherung werden Objekte oder Bereiche festgelegt an denen regelmäßige Kontrollen durchzuführen sind.
      
      <br />

      </td>
      <tr>
        <td>
      <ul style={{float:"left"}}>  
        <li>
          Erfassung von verkehrssicherheitsrelevanten Objekten​
        </li>
        <li>
          Kontrolldaten
        </li>
        <li>
          Direktes Anlegen von erforderlichen Maßnahmen und deren Abwicklung
        </li>
      </ul>
        </td>
      </tr>      
      </tr>
      

    </table>
    </BrowserView>
<MobileView>
<table>
      <tr>
      <td><img src={require("./Images/3-5.jpg")} style={{height:"auto", width:"30%"}} ></img></td>
      </tr>
      <tr>
      <td style={{display:"flex", justifyContent:"left", width:"90%"}}>
      Mit der Verkehrssicherung werden Objekte oder Bereiche festgelegt an denen regelmäßige Kontrollen durchzuführen sind.
      
      <br />

      </td>
      <tr>
        <td style={{display:"flex", justifyContent:"center", width:"70%"}}>
      <ul>  
      <li>
          Erfassung von verkehrssicherheitsrelevanten Objekten​
        </li>
        <li>
          Kontrolldaten
        </li>
        <li>
          Direktes Anlegen von erforderlichen Maßnahmen und deren Abwicklung
        </li>
      </ul>
        </td>
      </tr>      
      </tr>
      

    </table>
</MobileView>
  </div>
  </div>
  );
  }

