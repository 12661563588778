import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./style.css";
import {ThemeProvider, createTheme } from '@mui/material';


const theme = createTheme({
  
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row',
          fontSize: '1.5rem',
          padding: "0",
          overflow:"hidden",
          height: '64px',
          paddingLeft: "20px",
          paddingRight:"9px",
          color: "#ffffff",
          
        },

      },
    },
  },
  palette: {
    primary: {
      main: "#009900"
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    </React.StrictMode>
  </div>
);
